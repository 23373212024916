import { useState, useEffect } from 'react';

export const useUserData = () => {
    const [userData, setUserData] = useState(null);
    const [typing, setTyping] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${window.location.origin}/api/userData`);
                //const response = await fetch(`http://localhost/api/userData`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUserData(data[0]);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();

        const interval = setInterval(() => {
            setTyping((prev) => !prev);
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return { userData, typing, loading, error };
};