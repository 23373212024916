function Statement({ label, content, isLink = false }) {
    const renderContent = () => {
      if (Array.isArray(content)) {
        return content.map((item, index) => {
          // Check if the item is a string or an object with url and text
          if (typeof item === 'string') {
            // Handle string item
            return (
              <span key={index}>
                {item}{index < content.length - 1 ? ', ' : ''}
              </span>
            );
          } else if (isLink && item.url && item.text) {
            // Handle link object
            return (
              <span key={index}>
                <a href={item.url} rel="noopener noreferrer" target="_blank">{item.text}</a>
                {index < content.length - 1 ? ', ' : ''}
              </span>
            );
          }
          return null;
        });
      }
      return content;
    };
  
    return (
      <div className="statement">
        <div className="input-statement">{label}</div>
        <div className="return-statement">{renderContent()}</div>
      </div>
    );
  }
  
  export default Statement;